<template>
  <div>
    <HeaderPanel
      title="Serial Custom Object"
      :filter="filter"
      placeholder="Search Custom Object"
      @search="getData"
      :hasDropdown="false"
      :hasFilter="false"
      routerPath="/setting/product-setting/serial-custom-object/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(name)="{ item }">
              <router-link
                :to="'/setting/product-setting/serial-custom-object/' + item.id"
                ><u>{{ item.name }}</u>
              </router-link>
            </template>
            <template v-slot:cell(is_active)="{ item }">
              <div v-if="item.is_active" class="text-success">Active</div>
              <div v-else class="text-error">Inactive</div>
            </template>

            <template v-slot:cell(actions)="{ item }">
              <div class="d-flex justify-content-center">
                <router-link
                  :to="
                    '/setting/product-setting/serial-custom-object/' + item.id
                  "
                >
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="pencil-alt" title="Edit" />
                  </b-button>
                </router-link>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <Pagination
        @handleChangeTake="handleChangeTake"
        :filter="filter"
        :rows="rows"
        take="page_size"
        @pagination="pagination"
      />
    </div>
    <FooterAction routePath="/setting/product-setting" hideSubmit />
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";
import OtherLoading from "@/components/loading/OtherLoading";
// import Table from "./components/Table";
import SideBarFilter from "@/components/SideBarFilter";

export default {
  components: {
    OtherLoading,
    HeaderPanel,
    SideBarFilter,
    // Table,
  },
  data() {
    return {
      filter: {
        page: 1,
        page_size: 10,
        search: "",
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,

      items: [],
      styleDatetime: {
        width: "150%",
        border: "none",
      },
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "sort_order",
          label: "Sort Order",
        },
        {
          key: "is_active",
          label: "Status",
        },

        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },

  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.isBusy = true;
      let payload = { ...this.filter };

      this.axios
        .post(`/SerialProduct/serial_custom_object/list`, payload)
        .then((res) => {
          if (res.data.result) {
            this.rows = res.data.detail.total;
            this.items = res.data.detail.custom_object;
            this.isBusy = false;
          }
        });
    },

    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },

    filterPage(filter) {
      this.filter = filter;
      this.getData();
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        page_size: 10,
        status: [],
      };
    },
    stringName(item) {
      const first_name = item.first_name || "";
      const last_name = item.last_name || "";

      return first_name + " " + last_name;
    },
    handleChangeTake(value) {
      this.filter.page = 1;

      this.filter.page_size = value;
      this.getData();
    },
    pagination(page) {
      this.filter.page = page;
      this.getData();
    },
    chkBirthday(birthday) {
      let birthDate = birthday ? birthday.search("00:00:00") : -1;
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
    async exportFile(form) {
      this.isLoading = true;
      var url = `/transactioncode/code_report/${form.id}`;

      const data = await this.axios(url, {
        responseType: "blob",
      });
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data])); // change object.
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${form.name}_Code_report.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        alert("Export fail");
      }
      this.isLoading = false;
    },
    async deleteForm(id) {
      try {
        this.$bus.$emit("showLoading");
        const resp = await this.axios.post(`/CustomObject/delete_answer/${id}`);
        this.getData();
        this.$bus.$emit("hideLoading");
      } catch (error) {
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
  },
};
</script>
